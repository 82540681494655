import React, {useContext} from "react";
import classNames from "classnames";

import "./layout-header.scss";

import routes from "../../constants/routes";

import { LangPicker } from "../common/LangPicker/LangPicker";
import ReloadButton from "../ui/ReloadButton.jsx";
import {LangContext} from "../../App";

function Header({ className = "" }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "layout-header")}>
      <a href={i18n.t("common:mainLink")}>
        <img
          className="layout-header__logo"
          src="/images/logo.svg"
          alt="logo"
        />
      </a>

      <div className="layout-header__wrapper layout-header__wrapper--buttons">
        <ReloadButton className="layout-header__button" />

        <LangPicker theme="dark" />
      </div>
    </div>
  );
}

export default Header;
