import React, {useContext} from "react";

import { Avatar, Space } from "antd";
import PropTypes from "prop-types";

import "./player-card.scss";
import {LangContext} from "../../App.js";

export const PlayerCard = ({ player, actualNumber }) => {
  const i18n = useContext(LangContext)

  return <Space direction="vertical" size={16}>
    <div className="player-card">
      <div className="player-card__name">
        <span className="player-card__font player-card__font--name font--text">
          {player.name}
        </span>
      </div>

      {player.status === "inactive" ? (
        <div className="player-card__inactive">
          <span className="player-card__font player-card__font--name font--text">
            {i18n.t('common:attemptsOut')}
          </span>
        </div>
      ) : (
        <div className="player-card__numbers">
          {player.card.map((n, i) => {
            if (n === "win") {
              return (
                <img
                  key={i}
                  className="player-card__icon"
                  src="/images/check.svg"
                  alt="icon"
                />
              );
            }
            if (n === "error") {
              return (
                <img
                  key={i}
                  className="player-card__icon"
                  src="/images/cross-pink.svg"
                  alt="icon"
                />
              );
            } else {
              return (
                <Avatar
                  style={{
                    background: actualNumber === n ? '#9CBA42' : "#D9D9D9",
                    color: "black",
                    width: "25px",
                    height: "25px",
                  }}
                  key={i}
                  size="small"
                >
                  <span className="player-card__font player-card__font--number font--text">
                    {n}
                  </span>
                </Avatar>
              );
            }
          })}
        </div>
      )}
    </div>
  </Space>
};

PlayerCard.propTypes = {
  player: PropTypes.object,
  actualNumber: PropTypes.number,
};
