import React, { useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";

import useLocale from "../../hooks/useLocale";
import { LangContext } from "../../App";
import Default from "../../layout/Default";

import "./pages-welcome.scss";
import ColoredButton from "../../components/ui/ColoredButton";

export const WelcomePage = () => {
  const navigate = useNavigate();
  const { locale } = useParams();
  const i18n = useContext(LangContext);

  useLocale(locale);

  const download = () => {
    window.open(i18n.t('common:link'), "_blank", "noreferrer");
  };

  return (
    <Default className="pages-welcome">
      <div className="pages-welcome__container">
        <div className="pages-welcome__wrapper">
          <div className="pages-welcome__title">
            <span className="pages-welcome__font pages-welcome__font--title font--text">
              {i18n.t("questions:hi")}
            </span>
          </div>

          <div className="pages-welcome__subtitle">
            <span className="pages-welcome__font pages-welcome__font--subtitle font--text">
              {i18n.t("questions:startTitle")}
            </span>
          </div>

          <br/>
          <br/>
          <div className="pages-welcome__text">
            <span className="pages-welcome__font pages-welcome__font--subtitle font--text">
              {i18n.t("questions:startFormat")}
            </span>
          </div>
        </div>

        <div className="pages-welcome__buttons">
          <ColoredButton
            className="pages-welcome__button"
            onClick={() => navigate(`/${i18n.language}/choose-type`)}
          >
            {i18n.t("common:playOnline")}
          </ColoredButton>

          <ColoredButton
            className="pages-welcome__button"
            onClick={download}
          >
            {i18n.t("common:downloadGame")}
          </ColoredButton>
        </div>
      </div>
    </Default>
  );
};
