export default [
  {
    title: "Uk",
    code: "uk",
  },
  {
    title: "De",
    code: "de",
  },
  {
    title: "Pl",
    code: "pl",
  },
];
