import React, { useState, useContext } from "react";

import { message } from "antd";
import PropTypes from "prop-types";

import "./question.scss";

import { CurrentGameContext } from "../../providers";
import { Facts } from "../facts";
import { LangContext } from "../../App";
import OutlinedButton from "../ui/OutlinedButton";
import Radio from "../ui/Radio";

export const Question = ({
  className,
  question,
  setCurrentQuestion,
  showNewNumber,
}) => {
  const { q, users, number } = question;
  const i18n = useContext(LangContext);
  const randomItem = () => Math.floor(Math.random() * q?.questions?.length);
  const { currentGame, updateCurrentGame } = useContext(CurrentGameContext);
  const [answer, setAnswer] = useState();
  const [players, setPlayers] = useState(users);
  const [showFact, setShowFact] = useState(false);
  const [randomQuestion, setRandomQuestion] = useState(
    q?.questions[randomItem()]
  );
  const onChange = (value) => {
    setAnswer(value);
  };

  const removePlayerAfterAnswer = (player) => {
    const updPlayers = players.filter((pl) => pl.id != player.id);
    if (updPlayers.length > 0) {
      setRandomQuestion(q?.questions[randomItem()]);
    } else {
      setShowFact(true);
    }
    setPlayers(updPlayers);
  };

  const checkAnswer = () => {
    const index = players[0].card.findIndex((i) => i === number);
    if (answer === randomQuestion.correctAnswer) {
      players[0].card.splice(index, 1, `win`);
    } else {
      players[0].card.splice(index, 1, `error`);
      players[0].wrongAnswers = players[0].wrongAnswers + 1;
    }

    if (players[0].wrongAnswers === 3) {
      players[0].status = "inactive";
      if (currentGame.type === "multiplayer") {
        message.error(
          `${i18n.t("common:player")} ${players[0].name} ${i18n.t(
            "common:madeErrors"
          )}`
        );
      }
    }

    const idx = currentGame.players.findIndex((p) => p.id === players[0].id);
    currentGame.players[idx] = players[0];
    updateCurrentGame(currentGame);
    setRandomQuestion(null);
    removePlayerAfterAnswer(players[0]);
    setAnswer(null);
  };

  const factsProps = {
    setShowFact,
    setRandomQuestion,
    setCurrentQuestion,
    showNewNumber,
    fact: q.fact,
    title: q.category,
  };

  return (
    <>
      {showFact ? (
        <Facts {...factsProps} />
      ) : (
        <div className="question">
          <div className="question__title">
            <span className="question__font question__font--title font--text">
              {i18n.t("common:questionFor", {name: players.length && players[0].name})}
            </span>
          </div>

          <div className="question__text">
            <span className="question__font question__font--text font--text">
              {i18n.t(randomQuestion?.text)}
            </span>
          </div>

          <div className="question__answers">
            {randomQuestion?.answers.map((answer, i) => {
              return (
                <Radio key={i} value={answer} name="answer" onChange={onChange}>
                  <span className="question__font question__font--answer font--text">
                    {i18n.t(answer)}
                  </span>
                </Radio>
              );
            })}
          </div>

          <OutlinedButton
            className="question__button"
            color="pink"
            onClick={checkAnswer}
            disabled={!answer}
          >
            {i18n.t("common:answer")}
          </OutlinedButton>
        </div>
      )}
    </>
  );
};

Question.propTypes = {
  question: PropTypes.object,
  setCurrentQuestion: PropTypes.func.isRequired,
  showNewNumber: PropTypes.func.isRequired,
};
