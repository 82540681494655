import React, { useContext } from "react";
import classNames from "classnames";

import "./thank-you.scss";

import OutlinedButton from "../../ui/OutlinedButton";
import { LangContext } from "../../../App";
import routes from "../../../constants/routes";

function ThankYou({ className = "" }) {
  const i18n = useContext(LangContext);

  return (
    <div className={classNames(className, "thank-you")}>
      <div className="thank-you__wrapper">
        <img className="thank-you__image" src="/images/mark.png" alt="mark" />

        <div className="thank-you__title">
          <span className="thank-you__font thank-you__font--title font--title">
            {i18n.t("common:ThankYou")}
          </span>
        </div>

        <div className="thank-you__text">
          <span className="thank-you__font thank-you__font--text font--text">
            {i18n.t("common:LevelImproved")}
          </span>
        </div>
      </div>

      <OutlinedButton
        className="thank-you__button"
        href={i18n.t("common:mainLink")}
        color="pink"
      >
        {i18n.t("common:ToMain")}
      </OutlinedButton>
    </div>
  );
}

export default ThankYou;
