import { removedNumbers } from "../constants";

export const createUser = (name) => {
  return {
    id: Math.floor(Math.random() * 100000000),
    name,
    card: createCard(),
  };
};
export const generateMultiplayer = (names) => {
  const createdUsers = names.map((name) => createUser(name));
  return createdUsers;
};
const createCard = () => {
  let tempPull = [...Array(54).keys()].filter((n) => n != 0 && !removedNumbers.includes(n));
  const result = [...Array(12)].map(
    () => tempPull.splice(Math.floor(Math.random() * tempPull.length), 1)[0]
  );
  return result;
};
export const randomId = () => Math.floor(Math.random() * 1000);
